import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { postVerifyPhone } from "../../../api/api";
import Loading from "../../../components/Loading";
import InfoAction from "../../components/actionblock/Info";
import Banner from "../../components/Banner";

export default function VerifyEmail() {
    const { payload } = useParams();

    const verifyPhoneMutation = useMutation((payload) => postVerifyPhone(payload));

    useEffect(() => {
        verifyPhoneMutation.mutate(payload);
    }, [payload, verifyPhoneMutation]);

    return (
        <section className="verify_email">
            {verifyPhoneMutation.isLoading ? (
                <Loading />
            ) : (
                <>
                    {verifyPhoneMutation.isSuccess ? (
                        <Banner
                            component={
                                <InfoAction
                                    title="Je telefoonnummer is bevestigd!"
                                    text="Vergeet niet om ook je emailadres te bevestigen. Daarvoor heb je een mail van ons ontvangen. Let op: Die kan in je spambox terechtkomen."
                                    primaryActionButton={{ link: "/", text: "Naar het Landelijk Ouderpanel" }}
                                />
                            }
                        />
                    ) : (
                        <Banner
                            component={
                                <InfoAction
                                    title="Je telefoonnummer is niet bevestigd!"
                                    text={
                                        "Het is helaas niet gelukt om je telefoonnummer te bevestigen. Neem contact op met <a href='https://oudersenonderwijs.nl/contact/' target=_blank>Ouders & Onderwijs<a/>"
                                    }
                                    primaryActionButton={{ link: "/", text: "Naar het Landelijk Ouderpanel" }}
                                />
                            }
                        />
                    )}
                </>
            )}
        </section>
    );
}
